import React from 'react'
import { navigateTo } from 'gatsby-link'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import { filter, reject } from 'lodash'

import {
  Post,
  Control,
  Controls,
  Wrapper,
  Thumbnail,
  Title,
  IconWrapper,
  TextWrapper,
  Divider,
  Subject,
} from './styles'

const filterItems = (items, filterTerm) => {
  if (filterTerm === 'stories') {
    return filter(items, { node: { type: 'story' } })
  } else if (filterTerm === 'context') {
    return reject(items, { node: { type: 'story' } })
  }
  return items
}

const sortItems = items => {
  items.sort((a, b) => {
    const dateA = new Date(a.node.published)
    const dateB = new Date(b.node.published)
    return dateB - dateA
  })
  return items
}

const Content = props => (
  <div>
    <Controls>
      <Control
        active={props.filter !== 'stories' && props.filter !== 'context'}
        onClick={() => navigateTo('/content')}
      >
        All
      </Control>
      <Control
        active={props.filter === 'stories'}
        onClick={() => navigateTo('/content?filter=stories')}
      >
        Stories
      </Control>
      <Control
        active={props.filter === 'context'}
        onClick={() => navigateTo('/content?filter=context')}
      >
        Context
      </Control>
    </Controls>
    <Wrapper>
      {sortItems(filterItems(props.items, props.filter)).map(post => (
        <Post
          to={
            post.node.type === 'story'
              ? `/story/${post.node.slug}`
              : `/context/${post.node.slug}`
          }
          key={post.node.title}
        >
          <Thumbnail sizes={post.node.thumbnailImage.sizes}>image</Thumbnail>
          <TextWrapper>
            <Title>{post.node.title}</Title>
            {post.node.type === 'story' &&
              post.node.subject && (
                <Subject style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{post.node.type}</div>
                  <Divider />
                  {post.node.subject[0].name}{' '}
                  {post.node.subject[0].age && (
                    <span>, {post.node.subject[0].age}</span>
                  )}
                </Subject>
              )}
            {post.node.author && post.node.author.length === 1 ? (
              <Subject style={{ display: 'flex', alignItems: 'center' }}>
                <div>{post.node.type}</div>
                <Divider />
                {post.node.author[0].name}
              </Subject>
            ) : (
              post.node.type !== 'story' && (
                <Subject style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{post.node.type}</div>
                  <Divider />
                  Multiple Contributors
                </Subject>
              )
            )}
          </TextWrapper>
          <IconWrapper>
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </IconWrapper>
        </Post>
      ))}
    </Wrapper>
  </div>
)

export default Content
