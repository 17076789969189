import React from 'react'

import { Section, Text } from './styles'

const Head = () => (
  <div>
    <Section bg="white" color="black" pt="12rem" pb="10rem">
      <Text large>
        We want you to see them—refugees in our community—and ask, “Who are
        they? Why are they here? What are their stories? How can I support
        immigrants in my own community?”
      </Text>
    </Section>
  </div>
)

export default Head
