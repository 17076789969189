import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get } from 'lodash'

import ChangeTitle from 'containers/change-title'
import Head from '../components/Pages/Content/Head'
import Content from '../components/Pages/Content/Content'
import config from '../../data/SiteConfig'

const getTitle = (filter) => {
  console.log('get title')
  console.log(filter === 'stories')
    if (filter === 'stories') {
      return 'Stories'
    } else if (filter === 'context') {
      return 'Context'
    } else {
      return 'All Content'
    }
}

class ContentPage extends Component {
  constructor(props) {
    super(props)
    this.state = { filter: false }
  }

  componentWillReceiveProps(nextProps) {
    let filter
    if (nextProps.location.search === '?filter=stories') {
      filter = 'stories'
    } else if (nextProps.location.search === '?filter=context') {
      filter = 'context'
    } else {
      filter = false
    }
    this.setState({ filter })
  }

  render() {
    const data = get(this.props, 'data')
    // const changeTitle = get(this.props, 'changeTitle')
    const itemEdges = data.allContentfulStory.edges.concat(
      data.allContentfulContext.edges
    )

    return (
      <div className="content-container">
        <Helmet title={`Content | ${config.siteTitle}`} />
        <ChangeTitle title={getTitle(this.state.filter)} />
        <Head />
        <Content filter={this.state.filter} items={itemEdges} />
      </div>
    )
  }
}

export default ContentPage

/* eslint no-undef: "off" */
export const query = graphql`
  query ContentQuery {
    allContentfulStory {
      edges {
        node {
          id
          title
          slug
          type
          subject {
            name
            age
          }
          published
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    allContentfulContext {
      edges {
        node {
          id
          title
          slug
          type
          author {
            name
          }
          published
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
