import styled from 'styled-components'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import { color, maxWidth, space } from 'styled-system'

export const Section = styled.div`
  ${color};
  padding: 8rem;
  border-bottom: 1px solid;
  ${space};
  @media only screen and (max-width: 48rem) {
    padding: 6rem 3rem;
  }
  @media only screen and (max-width: 32rem) {
    padding: 6rem 2rem 3rem;
  }
`

export const Post = styled(Link)`
  display: flex;
  border-bottom: 1px solid;
  padding: 2rem;
  color: ${props => props.theme.colors.black};
  text-decoration: none !important;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
`

export const Controls = styled.div`
  display: flex;
  border-bottom: 1px solid;
`

export const Control = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  width: calc(100% / 3);
  text-align: center;
  border-right: 1px solid;
  padding: 2rem;
  font-size: 1.75rem;
  ${props =>
    props.active &&
    `color: ${props.theme.colors.white}; 
      background: ${props.theme.colors.black};
      pointer-events: none;
    `};
  ${props =>
    !props.active &&
    `&:hover {
      background: ${props.theme.colors.lightGray};
    }
    `};
  @media only screen and (max-width: 32rem) {
    font-size: 1rem;
    padding: 1rem;
  }
`

export const Wrapper = styled.div`
  ${maxWidth};
  margin: 0 auto;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 3rem;
  text-align: ${props => (props.large ? 'center' : 'left')};
  max-width: 20em;
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 62rem) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 32rem) {
    font-size: 1.5rem;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 48rem) {
    text-align: center;
  }
`

export const StyledLink = styled.a`
  color: inherit;
  font-weight: bold;
  background-image: linear-gradient(black, black);
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
`

export const Thumbnail = styled(Img)`
  width: 16rem;
  height: 16rem;
  margin-right: 4rem;
  img {
    object-fit: contain !important;
  }
  @media only screen and (max-width: 62rem) {
    width: 12rem;
    height: 12rem;
  }
  @media only screen and (max-width: 48rem) {
    margin: 0 auto;
  }
`

export const Title = styled.h2`
  margin-top: 0;
  margin-bottom: rem;
  font-size: 3rem;
  font-family: ${props => props.theme.fonts.serif};
  font-weight: 300;
  max-width: 18em;
  @media only screen and (max-width: 62rem) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 48rem) {
    margin-top: 1rem;
  }
`

export const Subject = styled.div`
  margin: 0;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.monospace};
  text-transform: uppercase;
  @media only screen and (max-width: 48rem) {
    justify-content: center;
  }
`

export const Chunk = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
  p {
    font-size: 2rem;
    font-family: ${props => props.theme.fonts.serif};
    margin-top: 0;
    width: 100%;
  }
  h3 {
    width: 33%;
    margin-top: 0.25rem;
    margin-right: 1rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
  }
`

export const IconWrapper = styled.div`
  justify-self: end;
  .svg-inline--fa {
    display: initial;
  }
  @media only screen and (max-width: 48rem) {
    display: none;
  }
`

export const Divider = styled.hr`
  display: inline-block;
  height: 1px;
  width: 3rem;
  border: 0;
  margin: 0 1em;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px ${props => props.theme.colors.black} solid;
  }
`
